import { formatName } from "../utils/format-name";

export const selectMedia = ({
  heading,
  catalogueNumber,
  composer,
  publisher,
  source,
  category,
}) => ({
  heading,
  composer,
  publisher,
  catalogueNumber,
  slug: formatName(heading),
  sample: source?.file,
  collection: category?.[0]?.collection?.[0],
});
