import { selectCategory } from "./category";

export const selectCollection = ({
  heading,
  subHeading,
  slug,
  categories,
  related,
  order,
}) => ({
  heading,
  subHeading,
  slug,
  categories: categories.map((cat) => selectCategory(cat)),
  related,
  order,
});
