export const formatPurchaseLink = ({
  catalogueNumber: _catalogueNumber,
  composer,
  heading,
}) => {
  const basePath =
    "https://musicshop.prsformusic.com/LMGR/inc/landing.aspx?newapp=True&thirdPartyApp=True";

  const catalogueNumber = _catalogueNumber
    ? `&catalogueNumber1=${_catalogueNumber}`
    : "";

  const composerLastName = composer
    ? `&composerLastName1=${composer.split(" ").slice(-1)}`
    : "";

  const workTitle = heading ? `&workTitle1=${heading.replace(/ /g, "+")}` : "";

  return `${basePath}${catalogueNumber}${composerLastName}${workTitle}`;
};
