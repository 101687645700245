import React, { useState } from "react";

import {
  Box,
  Button,
  Flex,
  Grid,
  Icon,
  Text,
  TextInput,
  chevronLeft,
  Image,
} from "../../components";

import { Details, Layout } from "../../patterns";

import { selectMedia } from "../../selectors";
import { formatPurchaseLink } from "../../utils/format-purchase-link";

const TrackTemplate = ({ pageContext }) => {
  const [validLicense, setValidLicense] = useState(false);

  const { heading, composer, publisher, catalogueNumber, collection } =
    selectMedia(pageContext);

  function handleChange(value) {
    if (!value) {
      setValidLicense(false);
    } else {
      setValidLicense(true);
    }
  }

  return (
    <Layout>
      <Flex $flexDirection="column" $alignItems="center" $gap={5}>
        <Box
          $bgColor="background.dark"
          $marginBottom={5}
          $paddingBottom={8}
          $paddingTop={7}
          $paddingX={5}
          $width="100%"
        >
          <Flex
            $flexDirection="column"
            $alignItems={{ xs: "flex-start", md: "center" }}
            $gap={5}
          >
            <div>
              <Text
                as="h1"
                $fontSize="heading2"
                $fontFamily="heading"
                $fontWeight="900"
                $textAlign={{ xs: "left", md: "center" }}
                $textTransform="uppercase"
              >
                {heading}
              </Text>

              {collection && (
                <Text
                  as="p"
                  $fontFamily="heading"
                  $fontSize="heading4"
                  $textAlign={{ xs: "left", md: "center" }}
                  $textTransform="uppercase"
                >
                  {collection.heading}
                </Text>
              )}
            </div>

            <Details
              composer={composer}
              publisher={publisher}
              catalogueNumber={catalogueNumber}
              $fontSize="bodySm"
              $marginBottom={5}
              $textAlign={{ xs: "left", md: "center" }}
            />

            <Text as="p">
              To purchase use of this track, please click on the following link
              to the PRS:
            </Text>

            <Flex
              $flexDirection={{ xs: "column", md: "row" }}
              $gap={5}
              $marginBottom={5}
              $maxWidth="640px"
              $width="100%"
            >
              <Button
                as="a"
                href={formatPurchaseLink({
                  composer,
                  catalogueNumber,
                  heading,
                })}
                target="_blank"
                $width="100%"
              >
                Purchase license
                <Image src="/logos/PM-logo-32-trans.png" $marginLeft={2} />
              </Button>

              <Button
                as="a"
                href="https://www.prsformusic.com/licences/using-production-music"
                target="_blank"
                $appearance="secondary"
                $width="100%"
              >
                How to use production music
              </Button>
            </Flex>

            <Text
              as="h3"
              $fontSize="heading3"
              $fontFamily="heading"
              $fontWeight="900"
              $textTransform="uppercase"
            >
              Got a license?
            </Text>

            <Grid
              $gridTemplateColumns="1fr 1fr"
              $maxWidth="640px"
              $width="100%"
            >
              <TextInput
                id="prs-license"
                type="text"
                placeholder="PRS Code"
                onChange={(e) => handleChange(e.target.value)}
              />

              <Button
                as="a"
                href={`/downloads/${catalogueNumber}.zip`}
                title={`Download ${heading}`}
                disabled={!validLicense}
                $appearance={validLicense ? "primary" : "disabled"}
              >
                Download
              </Button>
            </Grid>

            <Text $fontSize="small">
              Enter your PRS number to enable the download
            </Text>
          </Flex>
        </Box>
        {collection ? (
          <Button
            as="a"
            $appearance="secondary"
            href={`/collections/${collection.slug}`}
            $marginBottom={9}
          >
            <Icon
              src={chevronLeft}
              aria-hidden
              $marginRight={2}
              $marginLeft={-3}
            />
            {`Return to ${collection.heading}`}
          </Button>
        ) : (
          <Button as="a" $appearance="secondary" href={`/`} $marginBottom={9}>
            <Icon
              src={chevronLeft}
              aria-hidden
              $marginRight={2}
              $marginLeft={-3}
            />
            {`Return to Homepage`}
          </Button>
        )}
      </Flex>
    </Layout>
  );
};

export default TrackTemplate;
