const selectFeaturing = ({
  catalogueNumber,
  composer,
  heading,
  publisher,
}) => ({
  catalogueNumber,
  composer,
  heading,
  publisher,
});

const selectSample = ({ title, thumbnail, src, featuring }) => ({
  title,
  thumbnail: thumbnail.fluid.src,
  src,
  featuring: featuring ? selectFeaturing(featuring) : null,
});

export const selectSampleList = ({ title, samples }) => ({
  title,
  samples: samples.map((sample) => selectSample(sample)),
});
